import { formatDate } from '@/helpers/globalHelpers';

export default {
	name: 'DocumentDialog',
	props: {
		value: {
			type: Boolean,
			required: true,
		},
		document: {
			type: Object,
			required: false,
		},
	},
	data() {
		return {
			headers: [
				{
					text: '',
					value: 'fieldAgreement.label',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Anterior valor',
					value: 'old_value',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Nuevo valor',
					value: 'new_value',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
			],
		};
	},
	created() {},
	mounted() {},
	computed: {
		dialog: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {},
	methods: {
		closeDialog() {},
		formatDate,
	},
	components: {},
};
