const stateList = [
	{ value: 'PENDIENTE', text: 'Pendiente', color: 'blue' },
	{ value: 'LEGAL_REVISION', text: 'Revisión legal pendiente', color: 'blue' },
	{
		value: 'LEGAL_REVISION_OBSERVADO',
		text: 'Con observación de Legal',
		color: 'orange',
	},
	{
		value: 'LEGAL_REVISION_APROBADO',
		text: 'Revisión aprobada por Legal',
		color: 'blue',
	},
	{
		value: 'PROVEEDOR_APROBACION',
		text: 'Revisión de proveedor pendiente',
		color: 'blue',
	},
	{
		value: 'PROVEEDOR_APROBACION_APROBADO',
		text: 'Aprobado por Proveedor ',
		color: 'blue',
	},
	{
		value: 'PROVEEDOR_APROBACION_OBSERVADO',
		text: 'Con observación de Proveedor',
		color: 'orange',
	},
	{
		value: 'ABASTECIMIENTO_REVISION',
		text: 'Aprobación Pendiente de Gerencia Abastecimiento',
		color: 'blue',
	},
	{
		value: 'LEGAL_APROBACION',
		text: 'Aprobación Pendiente de Legal',
		color: 'blue',
	},
	{
		value: 'LEGAL_APROBACION_PREAPROBADO',
		text: 'Preaprobado por legal',
		color: 'blue',
	},
	{
		value: 'LEGAL_APROBACION_APROBADO',
		text: 'Aprobado',
		color: 'green',
	},

	//Con observación legal
	// { value: 'APPROVED', text: 'Aprobado', color: 'green' },
	// { value: 'OBSERVED', text: 'Observado', color: 'orange' },
	// { value: 'CONFIRMED', text: 'Confirmado', color: 'light-green' },
	// { value: 'EVALUATION', text: 'Evaluación', color: 'cyan ' },
	// { value: 'PRE-APPROVED', text: 'Preaprobado', color: 'lime' },
	// {
	// 	value: 'PREAPPROVED_SUPPLY',
	// 	text: 'Preaprobado por abastecimiento',
	// 	color: 'teal',
	// },
	// { value: 'APPROVED_SUPPLY', text: 'Aprobado por Abastecimiento', color: 'blue' },
	// { value: 'APPROVED_PROVIDER', text: 'Aprobado por Proveedor', color: 'light-blue' },
	// {
	// 	value: 'OBSERVED_PROVIDER',
	// 	text: 'Observado por proveedor',
	// 	color: 'orange',
	// },
	// { value: 'APPROVED_LEGAL', text: 'Aprobado por Legal', color: 'green' },
	// { value: 'OBSERVED_LEGAL', text: 'Observado por Legal', color: 'orange' },
	// { value: 'PREAPPROVED_LEGAL', text: 'Preabrobado por Legal', color: 'light-blue' },
];

const getStatus = (value) => {
	const found = stateList.find((e) => e?.value == value);
	return found ? found : { value, text: value };
};

export { getStatus, stateList };
