<template>
	<v-autocomplete
		item-text="name"
		label="Ingrese razón social o ruc"
		dense
		outlined
		no-filter
		hide-details
		persistent-placeholder
		clearable
		:item-value="entry ? entry : 'document'"
		:value="owner"
		:items="owners"
		:loading="loading"
		@input="(v) => setValue(v)"
		@update:search-input="(val) => listenSearch(val)"
	>
	</v-autocomplete>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
	name: 'SearchDocument',
	props: ['value', 'entry'],
	data: () => ({
		timeout: null,
		loading: false,
		input: null,
		dialog: false,
	}),
	created() {},
	computed: {
		...mapState('owner', ['owners']),
		owner: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},

	watch: {},
	methods: {
		...mapActions('owner', ['listOwners']),
		listenSearch(value) {
			clearTimeout(this.timeout);
			if ((value || '').length > 0 || value == '') {
				this.timeout = setTimeout(async () => {
					this.loading = true;
					await this.listOwners({ q: value });
					this.loading = false;
					clearTimeout(this.timeout);
				}, 800);
			}
		},
		setValue(value) {
			this.$emit('input', value);
		},
		async setOwner(text) {
			if (text) {
				this.setValue(text);
				await this.listOwners({ q: text });
			}
		},
	},
	destroyed() {
		clearTimeout(this.timeout);
	},
	components: {},
};
</script>
