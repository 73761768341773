export default {
	name: 'KeywordResultsSection',
	props: {
		results: {
			type: [Array],
			required: false,
		},

		keywordSearch: {
			type: [String],
			required: false,
		},
	},
	data() {
		return {
			headers: [
				{
					text: '',
					value: 'fieldAgreement.label',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: '',
					value: 'value',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
			],
		};
	},
	created() {},
	mounted() {},
	computed: {},
	watch: {},
	methods: {
		paintWord(text) {
			if (this.keywordSearch) {
				const lowerSearch = (this.keywordSearch || '').toLowerCase();
				let regex = new RegExp(lowerSearch, 'gi');
				return text.replace(
					regex,
					'<span style="background-color: yellow;">$&</span>'
				);
			} else {
				return text;
			}
		},
	},
	beforeDestroy() {},
	components: {},
};
