import { VueEditor } from 'vue2-editor';
import { mapActions } from 'vuex';
import { $EventBus } from '@/main';
import CDatePicker from '@/components/global/CDatePicker.vue';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import QrDocument from '@/components/agreement/elements/QrDocument/QrDocument.vue';
import DocumentDialog from '@/components/agreement/elements/DocumentDialog/DocumentDialog.vue';
import ContractSheetDialog from '@/components/agreement/elements/ContractSheetDialog/ContractSheetDialog.vue';
import DownloadFileBtn from '@/components/agreement/elements/DownloadFileBtn/DownloadFileBtn.vue';
import NotificationUsersDialog from '@/components/agreement/elements/NotificationUsersDialog/NotificationUsersDialog.vue';
import ApproversSection from '@/components/agreement/elements/ApproversSection/ApproversSection.vue';
import KeywordResultsSection from '@/components/agreement/elements/KeywordResultsSection/KeywordResultsSection.vue';
import { getStatus } from '@/helpers/statusAgreement.js';
import { formatDate } from '@/helpers/globalHelpers';

export default {
	name: 'PreviewContract',
	props: {
		value: {
			type: Array,
			required: false,
		},
		agreement: {
			type: Object,
			required: false,
		},
		keywordSearch: {
			type: String,
			required: false,
		},
		currentAgreement: {
			type: Object,
			required: false,
		},
	},
	data() {
		return {
			valid: true,
			dialogSheet: false,
			dialogDocument: false,
			dialogSheetEdit: false,
			document: null,
			loadingCreateNotification: null,
			openNotificationUsersDialog: false,
			requiredRule: [(v) => !!v || 'Campo requerido'],
			numberRules: [
				(v) => !!v || 'Campo requerido',
				(v) => /^\d*$/.test(v) || 'Campo numérico',
				(v) => v > 0 || 'El valor debe ser mayor que cero',
			],
			headers: [
				{
					text: 'Modificado',
					value: 'created_at',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Añadido por:',
					value: 'user.fullName',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Comentarios',
					value: 'comment',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: '',
					value: 'actions',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
			],
			headersAgreementWord: [
				{
					text: 'Fecha',
					value: 'created_at',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Añadido por:',
					value: 'user.fullName',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: '',
					value: 'actions',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
			],
		};
	},
	created() {
		//this.setValues();
	},
	mounted() {},
	computed: {
		fields: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		isEditable() {
			return ['PENDING', 'OBSERVED'].includes(this.agreement?.status);
		},
	},
	watch: {},
	methods: {
		...mapActions('agreement', ['createAgreementNotification']),

		getStatus,
		formatDate,

		showSnack(error, color = 'success') {
			let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join('<br> ') : msmError;
			$EventBus.$emit('showSnack', color, msmError);
		},

		getField(key) {
			const fields =
				Array.isArray(this.agreement?.agreementForm) &&
				this.agreement?.agreementForm;
			const found = fields.find((e) => e?.fieldAgreement?.key == key);
			return found?.value || null;
		},

		openDocumentDialog(v) {
			this.document = v;
			this.dialogDocument = true;
		},

		async saveAgreemenNotification() {
			this.loadingCreateNotification = true;
			const data = { agreement_id: this.agreement.id };
			const { ok, response, error } = await this.createAgreementNotification(data);
			if (ok) {
				let msm = response?.message;
				msm = Array.isArray(msm) ? msm.join('<br> ') : msm;
				$EventBus.$emit('showSnack', 'success', msm);
				this.$router.push({
					name: 'ReadAgreement',
					params: {
						agreementId: this.agreement.id,
					},
				});
			} else {
				this.showSnack(error, 'error');
			}
			this.loadingCreateNotification = false;
		},
	},
	components: {
		ValidationAlert,
		CDatePicker,
		VueEditor,
		ContractSheetDialog,
		DocumentDialog,
		QrDocument,
		DownloadFileBtn,
		NotificationUsersDialog,
		ApproversSection,
		KeywordResultsSection,
	},
};
