<template>
	<div>
		<v-btn
			color="primary"
			:loading="loading"
			class="my-2"
			small
			icon
			@click="openFileDialog"
		>
			<v-icon>mdi-upload</v-icon>
		</v-btn>
		<input ref="fileInput" type="file" style="display: none" @change="onFileChange" />
	</div>
</template>
<script>
import { createAgreementAnnex } from '@/store/modules/agreement/service/agreement.service';
import { showSnack } from '@/helpers/globalHelpers';
export default {
	name: 'ButtonFile',
	props: {
		value: {
			type: Array,
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
		annex: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		loading: false,
	}),
	computed: {
		files: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		computedDateFormatted() {
			return this.formatDate(this.date);
		},
	},
	methods: {
		openFileDialog() {
			this.$refs.fileInput.click();
		},

		onFileChange(event) {
			const file = event?.target?.files[0] || [];
			this.sendCreateAgreementAnnex(file);
		},

		async sendCreateAgreementAnnex(file) {
			try {
				this.loading = true;
				let formData = new FormData();
				formData.append('required_annex_id', this.annex?.id);
				formData.append('file', file, file?.name);
				await createAgreementAnnex(formData);
				this.$emit('change');
			} catch (error) {
				showSnack(error?.response?.data?.message, 'error');
			} finally {
				this.loading = false;
				this.$refs.fileInput.value = null;
			}
		},
	},
};
</script>
