<template>
	<search-agreement />
</template>
<script>
import SearchAgreement from '@/components/agreement/SearchAgreement/SearchAgreement.vue';

export default {
	name: 'SearchAgreementPage',
	components: { SearchAgreement },
};
</script>
