import { mapState, mapActions } from 'vuex';
import { $EventBus } from '@/main';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import CDatePicker from '@/components/global/CDatePicker.vue';
import DecoupledDocument from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/es.js';
import moment from 'moment';
export default {
	name: 'ContractSheetDialog',
	props: {
		value: {
			type: Boolean,
			required: true,
		},
		responses: {
			type: Array,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
		type: {
			type: String,
			required: false,
		},
		contractId: {
			type: Number,
			required: false,
		},
		document: {
			type: Object,
			required: false,
		},
		permissions: {
			type: Object,
			required: false,
		},
		agreement: {
			type: Object,
			required: false,
		},
	},

	data() {
		return {
			valid: true,
			fields: [],
			loadingData: false,
			requiredRule: [(v) => !!v || 'Campo requerido'],
			numberRules: [
				(v) => !!v || 'Campo requerido',
				(v) => /^\d*$/.test(v) || 'Campo numérico',
				(v) => v > 0 || 'El valor debe ser mayor que cero',
			],

			loading: false,
			loadingTemplate: false,
			comment: null,
			editor: DecoupledDocument,
			editorData: '',
			editorConfig: {
				language: 'es',
				toolbar: {
					removeItems: [
						'uploadImage',
						'mediaEmbed',
						'strikethrough',
						'insertTable',
						'indent',
						'outdent',
						'link',
					],
					shouldNotGroupWhenFull: true,
				},
			},
		};
	},
	created() {},
	mounted() {},
	computed: {
		...mapState('agreement', ['template']),
		dialog: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		myResponses: {
			get() {
				return this.responses || false;
			},
			set(value) {
				this.$emit('setResponses', value);
			},
		},
	},
	watch: {
		value(newVal) {
			if (newVal) {
				this.showTemplate();
			}
		},
	},
	methods: {
		...mapActions('agreement', ['getTemplate', 'updateAgreement', 'getAgreement']),

		async showTemplate() {
			this.loadingTemplate = true;
			const { ok, error } = await this.getTemplate(
				this.agreement?.agreement_type_id
			);
			if (ok) {
				this.setValues();
			} else {
				this.showError(error);
			}
			this.loadingTemplate = false;
		},

		async update() {
			const isvalidated = this.$refs.form.validate();
			if (isvalidated) {
				this.loading = true;
				let fields = [];
				this.fields.forEach((group) => {
					group.items.forEach((item) => {
						fields.push({ field_agreement_id: item.id, value: item.value });
					});
				});

				const data = {
					agreement_id: this.agreement?.id,
					comment: this.comment,
					agreement_field: fields,
				};
				const { ok, response, error } = await this.updateAgreement(data);
				if (ok) {
					$EventBus.$emit('showSnack', 'success', response.message[0]);
					//this.getAgreement(this.agreement.id);
					this.$emit('update')
					this.dialog = false;
				} else {
					this.showError(error);
				}
				this.loading = false;
			}
		},

		showError(error) {
			let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join('<br> ') : msmError;
			$EventBus.$emit('showSnack', 'error', msmError);
		},

		setValues() {
			let fields = [...(this.template?.fields || [])];
			const values = [...this.agreement?.agreementForm];

			if (Array.isArray(fields) && Array.isArray(values) && values.length > 0) {
				fields = fields.map((group) => {
					let items = group.items.map((item) => {
						const found = values.find(
							(res) => res.field_agreement_id == item.id
						);
						return { ...item, value: found ? found.value : null };
					});
					return { ...group, items };
				});
			}
			this.fields = fields;
		},
		closeDialog() {
			this.fields = [];
			this.$nextTick(() => {
				if (this.$refs.form) {
					this.$refs.form.resetValidation();
				}
			});
		},
		onReady(editor) {
			// Insert the toolbar before the editable area.
			editor.ui
				.getEditableElement()
				.parentElement.insertBefore(
					editor.ui.view.toolbar.element,
					editor.ui.getEditableElement()
				);
		},
		ValidMinDate(fields) {
			const startdate = fields.find((items) => items.key === 'start-date');
			const enddate = fields.find((items) => items.key === 'end-date');

			const momentStartDate = moment(startdate?.value, 'YYYY-MM-DD');
			const momentEndDate = moment(enddate?.value, 'YYYY-MM-DD');

			if (momentStartDate.isAfter(momentEndDate)) {
				delete enddate.value;
			}

			return startdate?.value;
		},
	},
	components: {
		ValidationAlert,
		CDatePicker,
	},
};
