import { $EventBus } from '@/main';
import moment from 'moment';
import 'moment-timezone';

function alertUserNotification(items) {
	if (Array.isArray(items) && items?.length === 0) {
		$EventBus.$emit(
			'showSnack',
			'info',
			'La búsqueda solo muestra resultados de usuarios que tienen los permisos para esta aplicación.',
			'7000'
		);
		return true;
	} else {
		return false;
	}
}

const showSnack = (message, color = 'success') => {
	message = Array.isArray(message) ? message.join('<br> ') : message;
	$EventBus.$emit('showSnack', color, message);
};

const formatDate = (date, format = 'lll') => {
	try {
		const companyId = localStorage.getItem('company_id');
		const companies = [
			{
				companyId: 1,
				companyName: 'Confipetrol Perú',
				companyTimeZone: 'America/Lima',
			},
			{
				companyId: 2,
				companyName: 'Confipetrol Chile',
				companyTimeZone: 'America/Santiago',
			},
		];
		const companyFound = companies.find((e) => e?.companyId == companyId);
		const optionaltz = Intl.DateTimeFormat().resolvedOptions().timeZone;

		if (!isNaN(new Date(date))) {
			moment.locale('es');
			const tz = companyFound?.companyTimeZone || optionaltz;
			const utecTime = moment.utc(date).tz(tz);
			return utecTime.format(format);
		} else {
			return null;
		}
	} catch (error) {
		return null;
	}
};

const isArray = (arr) => {
	return Array.isArray(arr) ? arr : [];
};

const isObject = (variable) => {
	return Object.prototype.toString.call(variable) === '[object Object]';
};

const showError = (errorResponse) => {
	const status = errorResponse?.status || null;
	const data = errorResponse?.data || null;
	let errorMessage =
		'Lo sentimos, ocurrió un error. Por favor, inténtalo nuevamente más tarde.';

	if (status === 422) {
		errorMessage = data?.message;
	} else if (status === 400 || status === 403) {
		errorMessage = Array.isArray(data?.message)
			? data?.message.join('<br> ')
			: data?.message;
	} else if (status === 404) {
		errorMessage = data?.message;
	}
	$EventBus.$emit('showSnack', 'error', errorMessage);
};

const showSnackBar = (message, color = 'info') => {
	if (message) {
		$EventBus.$emit('showSnack', color, message);
	}
};

export { alertUserNotification, showSnack, isArray, isObject, formatDate, showError, showSnackBar };
