import ButtonFile from '@/components/global/ButtonFile.vue';
import { $EventBus } from '@/main';
import { listAnnexesByType } from '@/store/modules/agreement/service/agreement.service';

export default {
	name: 'AnnexRegistration',
	props: {
		value: {
			type: [Array],
			required: true,
		},
		agreementTypeId: {
			type: [Number, String],
			required: true,
		},
	},
	data() {
		return {
			headers: [
				{
					text: 'Anexo',
					value: 'name',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},

				{
					text: 'Obligatorio',
					value: 'required',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Para proveedor',
					value: 'requiredProvider',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Quitar',
					value: 'remove',
					sortable: false,
					align: 'center',
					class: 'primary--text',
				},
			],
			loading: false,
			annexForm: {
				name: null,
				flag_mandatory: true,
				flag_required_for_provider: false,
				default: false,
			},
			// fileRule: [
			// 	(v) => !!v || 'Campo requerido',
			// 	(v) => this.validationExtension(v),
			// 	(value) =>
			// 		!value ||
			// 		value.size < 2000000 ||
			// 		'¡El tamaño de la imagen debe ser inferior a 2 MB!',
			// ],
		};
	},
	created() {
		this.getAnnexesByType();
	},
	mounted() {},
	computed: {
		annexes: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {},
	methods: {
		addAnnex() {
			this.annexes.push({ ...this.annexForm });
			this.$nextTick(() => {
				this.annexForm.name = null;
			});
		},

		removeAnnex(index) {
			this.annexes.splice(index, 1);
		},

		formattingAnnexes(annexes) {
			if (Array.isArray(annexes)) {
				return annexes.map((annex) => {
					return {
						default: true,
						name: annex?.name,
						flag_required_for_provider: false,
						flag_mandatory: annex?.flag_mandatory,
					};
				});
			} else {
				return annexes;
			}
		},

		async getAnnexesByType() {
			try {
				const queryParams = {
					agreement_type_id: this.agreementTypeId,
				};
				const response = await listAnnexesByType(queryParams);
				const annexes = response?.data || [];
				this.annexes = this.formattingAnnexes(annexes);
			} catch (error) {
				this.showSnack(error?.response?.data?.message, 'error');
			}
		},

		showSnack(message, color = 'success') {
			message = Array.isArray(message) ? message.join('<br> ') : message;
			$EventBus.$emit('showSnack', color, message);
		},
	},
	beforeDestroy() {},
	components: {
		ButtonFile,
	},
};
