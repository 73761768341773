import { mapActions } from 'vuex';

export default {
	name: 'DownloadFileBtn',
	props: {
		id: {
			type: [Number, String],
			required: true,
		},
		type: {
			type: [String],
			required: false,
		},
		label: {
			type: [String],
			required: false,
		},
		text: {
			type: [Boolean],
			required: false,
		},
		name: {
			type: [String],
			required: false,
		},
	},
	data() {
		return {
			base64: null,
			loading: false,
		};
	},
	created() {
		//this.getToken();
	},
	mounted() {},
	computed: {},
	watch: {},
	methods: {
		...mapActions('agreement', ['getFile']),
		async downloadfile() {
			this.loading = true;
			const { ok, response } = await this.getFile(this.id);
			if (ok) {
				const linkSource = response?.data?.file;
				const downloadLink = document.createElement('a');
				downloadLink.href = linkSource;
				downloadLink.download = this.name || 'Contrato';
				downloadLink.click();
			}
			this.loading = false;
		},
	},
	components: {},
};
