import { formatDate, showSnack } from '@/helpers/globalHelpers';
import { listAgreementApprover } from '@/store/modules/agreement/service/agreement.service';

export default {
	name: 'ApproversSection',
	props: {
		agreement: {
			type: [Object],
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
	},
	data() {
		return {
			headers: [
				{
					text: 'Nombre',
					value: 'user.fullName',
					sortable: false,
					align: 'left',
				},
				{
					text: 'Fecha',
					value: 'replied_date',
					sortable: false,
					align: 'left',
				},
				{
					text: 'Área',
					value: 'area',
					sortable: false,
					align: 'left',
				},
				{
					text: '',
					value: 'icon',
					sortable: false,
					align: 'right',
				},
			],
			approvals: [],
			loading: false,
		};
	},
	created() {
		this.getApprovers();
	},
	mounted() {},
	computed: {},
	watch: {
		agreement() {
			this.getApprovers();
		},
	},
	methods: {
		formatDate,
		getApprovers: async function () {
			try {
				this.loading = true;
				this.approvals = [];
				const response = await listAgreementApprover({
					agreement_id: this.agreement?.id,
				});
				this.approvals = Array.isArray(response?.data) ? response?.data : [];
			} catch (error) {
				showSnack(error?.response?.data?.message || error.toString(), 'warning');
			} finally {
				this.loading = false;
			}
		},
		printArea: function (status = '') {
			const arr = [				
				{ status: 'PROVEEDOR_APROBACION_APROBADO', area: 'Proveedor' },				
				{ status: 'LEGAL_APROBACION_PREAPROBADO', area: 'Legal' },
				{ status: 'LEGAL_APROBACION_APROBADO', area: 'Legal' },
			];
			const found = arr.find(
				(e) => e?.status?.toLowerCase() === status?.toLowerCase()
			);
			return found ? found?.area : '-';
		},
	},
	beforeDestroy() {},
	components: {},
};
