import { mapState, mapActions } from 'vuex';
import { $EventBus } from '@/main';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import CDatePicker from '@/components/global/CDatePicker.vue';
//import { alertUserNotification } from '@/helpers/globalHelpers';

export default {
	name: 'NotificationUsersDialog',
	props: {
		value: {
			type: Boolean,
			required: false,
		},
		agreementId: {
			type: [String, Number],
			required: true,
		},
	},
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			userForm: {},
			userTable: [],
			users: [],
			requiredRule: [(v) => !!v || 'Campo requerido'],
			numberRules: [
				(v) => !!v || 'Campo requerido',
				(v) => /^\d*$/.test(v) || 'Campo numérico',
				// (v) => v > 0 || 'El valor debe ser mayor que cero',
			],
			headers: [
				{
					text: 'Usuario',
					value: 'user.fullName',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Email',
					value: 'user.email',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: '',
					value: 'actions',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
			],
			loadingUsers: false,
			loadingSave: false,
			loadingSearch: false,
			timeout: null,
		};
	},
	created() {
		//this.getUsers();
		this.getNotificationUsers();
	},
	mounted() {},
	computed: {
		...mapState('security', ['usersActive']),
		...mapState('agreement', ['template']),
		myModel: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {
		dialog(newVal) {
			if (newVal && this.$refs.userForm) this.$refs.userForm.reset();
		},
	},
	methods: {
		...mapActions('security', ['searchUsers']),
		...mapActions('agreementNotification', [
			'readNotificationUsers',
			'createNotificationUser',
			'deleteNotificationUser',
		]),

		showAlert(msmError, color = 'error') {
			//let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join('<br> ') : msmError;
			$EventBus.$emit('showSnack', color, msmError);
		},

		closeDialog() {
			this.$nextTick(() => {
				if (this.$refs?.form) {
					this.$refs.form.resetValidation();
				}
			});
		},

		async getUsers(params) {
			this.loading = true;
			const { ok, response, error } = await this.searchUsers(params);
			if (ok) {
				this.users = Array.isArray(response) ? response : [];
				//alertUserNotification(response);
			} else {
				this.showAlert(error?.data?.message || 'Error');
			}
			this.loading = false;
		},

		async getNotificationUsers() {
			this.loadingUsers = true;
			const { ok, response, error } = await this.readNotificationUsers({
				agreement_id: this.agreementId,
			});
			if (ok) {
				this.userTable = Array.isArray(response?.data) ? response?.data : [];
			} else {
				this.showAlert(error?.data?.message || 'Error');
			}
			this.loadingUsers = false;
		},

		async addUser() {
			const isvalidated = this.$refs.userForm.validate();
			if (isvalidated) {
				const isDuplicated = this.userTable.some(
					(e) => e?.user?.id == this.userForm.user?.id
				);
				if (!isDuplicated) {
					this.loadingSave = true;
					const { ok, response, error } = await this.createNotificationUser({
						user_id: this.userForm?.user?.id,
						agreement_id: parseInt(this.agreementId),
					});
					if (ok) {
						this.getNotificationUsers();
						this.showAlert(response?.message || 'Guardado', 'success');
						this.cleanForm();
					} else {
						this.showAlert(error?.data?.message || 'Error');
					}
					this.loadingSave = false;
				}
			}
		},

		async removeUser(item) {
			const { isConfirmed } = await this.$swal.fire({
				title: '¿Estás seguro de que quieres eliminar?',
				text: '',
				icon: 'question',
				showCancelButton: true,
				cancelButtonColor: '#e0e0e0',
				confirmButtonColor: '#0039a6',
				cancelButtonText: 'No',
				confirmButtonText: 'Sí',
				reverseButtons: true,
			});
			if (isConfirmed) {
				const { ok, response, error } = await this.deleteNotificationUser({
					agreementId: item?.id,
				});
				if (ok) {
					this.getNotificationUsers();
					this.showAlert(response?.message || 'Eliminado', 'success');
				} else {
					this.showAlert(error?.data?.message || 'Error');
				}
			}
			this.cleanForm();
		},

		cleanForm() {
			this.$nextTick(() => {
				this.userForm.user = null;
				if (this.$refs.userForm) this.$refs.userForm.reset();
			});
		},

		listenSearch(value) {
			clearTimeout(this.timeout);
			if ((value || '').length > 1 || value == '') {
				this.timeout = setTimeout(async () => {
					await this.getUsers({ q: value });
					clearTimeout(this.timeout);
				}, 800);
			}
		},
	},
	components: {
		ValidationAlert,
		CDatePicker,
	},
};
