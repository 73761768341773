import QrcodeVue from 'qrcode.vue';
import { mapActions } from 'vuex';
//import { urlApi } from '@/store/api/config.js';

export default {
	name: 'QrDocument',
	props: {
		id: {
			type: [Number, String],
			required: true,
		},
		type: {
			type: [String],
			required: false,
		},
		label: {
			type: [String],
			required: false,
		},
		module: {
			type: [String],
			required: false,
		},
	},
	data() {
		return {
			token: null,
		};
	},
	created() {
		this.getToken();
	},
	mounted() {},
	computed: {
		qrValue() {
			//return this.token ? `${urlApi}/agreement/download/${this.token}` : '';
			if (this.module === 'CONTRACT') {
				return `${window.location.origin}/dashboard/contract/agreement/${this.id}/editor`;
			} else {
				return `${window.location.origin}/dashboard/supply/${this.id}/precontract`;
			}
		},
	},
	watch: {},
	methods: {
		...mapActions('agreement', ['getDocumentToken']),
		async getToken() {
			const { ok, response } = await this.getDocumentToken(this.id);
			if (ok) {
				this.token = response?.data;
			}
		},
	},
	components: {
		QrcodeVue,
	},
};
