import { mapState, mapActions } from 'vuex';
import { VueEditor } from 'vue2-editor';
import { $EventBus } from '@/main';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import CDatePicker from '@/components/global/CDatePicker.vue';
import EditorField from '@/components/agreement/elements/EditorField/EditorField.vue';
import DownloadFileBtn from '@/components/agreement/elements/DownloadFileBtn/DownloadFileBtn.vue';
import AnnexRegistration from '@/components/agreement/elements/AnnexRegistration/AnnexRegistration.vue';

import moment from 'moment';
export default {
	name: 'ContractForm',
	props: {
		value: {
			type: Array,
			required: true,
		},
		responses: {
			type: Array,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
		type: {
			type: String,
			required: false,
		},
		info: {
			type: Object,
			required: false,
		},
	},
	data() {
		return {
			valid: true,
			requiredRule: [(v) => !!v || 'Campo requerido'],
			numberRules: [
				(v) => !!v || 'Campo requerido',
				(v) => /^\d*$/.test(v) || 'Campo numérico',
				(v) => v > 0 || 'El valor debe ser mayor que cero',
			],
			loading: false,
			name: null,
			comment: null,

			agreement_id: null,
			agreement_saved: {},
			mode: 'CREATE',
			annexes: [],
		};
	},
	created() {
		this.setValues();
	},
	mounted() {},
	computed: {
		...mapState('agreement', ['template']),
		fields: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		myResponses: {
			get() {
				return this.responses || false;
			},
			set(value) {
				this.$emit('setResponses', value);
			},
		},
	},
	watch: {},
	methods: {
		...mapActions('agreement', ['createAgreement', 'updateAgreement', 'getFile']),
		setValues() {
			let fields = [...(this.template?.fields || [])];
			if (
				Array.isArray(fields) &&
				Array.isArray(this.myResponses) &&
				this.myResponses.length > 0
			) {
				fields = fields.map((group) => {
					let items = group.items.map((item) => {
						const found = this.myResponses.find(
							(res) => res.field_id == item.id
						);
						return { ...item, value: found ? found.value : null };
					});
					return { ...group, items };
				});
			}
			this.fields = fields;
		},

		async saveAgreement() {
			let isvalidated = this.$refs.formContract.validate();
			if (isvalidated) {
				this.loading = true;
				let data = {
					agreement: {},
					agreement_field: [],
					agreement_required_annex: [],
				};

				if (this.mode == 'CREATE') {
					data.agreement = {
						...this.info,
						name: this.name,
						status: 'DRAFT',
						company_id: parseInt(localStorage.getItem('company_id')),
						comment: this.comment,
					};
				} else if (this.mode == 'UPDATE') {
					data.agreement_id = this.agreement_id;
					data.comment = this.comment;
				}
				this.fields.forEach((group) => {
					group.items.forEach((item) => {
						data.agreement_field.push({
							field_agreement_id: item?.id,
							value: item?.value,
						});
					});
				});

				data.agreement_required_annex = this.annexes.map((annex) => {
					delete annex.default;
					return annex;
				});

				const { ok, error, response } =
					this.mode == 'CREATE'
						? await this.createAgreement(data)
						: await this.updateAgreement(data);
				if (ok) {
					this.agreement_id = response?.data?.id;
					this.agreement_saved = response?.data;
					this.mode = 'SAVED';
					//this.setUrlWord();
				} else {
					this.showSnack(error, 'error');
					this.isSaved = false;
				}
				this.loading = false;
			} else {
				$EventBus.$emit('showSnack', 'warning', 'Completar formulario');
			}
		},

		updateForm() {
			this.mode = 'UPDATE';
		},

		showSnack(error, color = 'success') {
			let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join('<br> ') : msmError;
			$EventBus.$emit('showSnack', color, msmError);
		},

		printMinMaxDate(fields, key, type) {
			const startdate = fields.find((items) => items.key === 'start-date');
			const enddate = fields.find((items) => items.key === 'end-date');

			if (key === 'start-date' && type === 'max' && enddate) {
				return moment(enddate?.value, 'YYYY-MM-DD').format('YYYY-MM-DD');
			} else if (key === 'end-date' && type === 'min' && startdate) {
				return moment(startdate?.value, 'YYYY-MM-DD').format('YYYY-MM-DD');
			}
			return null;
		},
		validDate(key, fields) {
			const startdate = fields.find((items) => items.key === 'start-date');
			const enddate = fields.find((items) => items.key === 'end-date');
			const momentStartDate = moment(startdate?.value, 'YYYY-MM-DD');
			const momentEndDate = moment(enddate?.value, 'YYYY-MM-DD');
			if (key === 'start-date' && enddate?.value) {
				const result = momentStartDate.isBefore(momentEndDate);
				return result || 'La fecha debe ser menor';
			} else if (key === 'end-date' && startdate?.value) {
				const result = momentEndDate.isAfter(momentStartDate);
				return result || 'La fecha debe ser mayor';
			}
			return true;
		},
	},
	components: {
		ValidationAlert,
		CDatePicker,
		VueEditor,
		EditorField,
		DownloadFileBtn,
		AnnexRegistration,
	},
};
