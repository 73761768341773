import DecoupledDocument from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/es.js';

export default {
	name: 'EditorField',
	props: {},
	data: () => ({
		editor: DecoupledDocument,
		editorConfig: {
			language: 'es',
			toolbar: {
				removeItems: ['uploadImage', 'mediaEmbed'],
			},
		},
	}),
	computed: {
		editorData: {
			get() {
				return this.value || '';
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {},
	created() {},
	methods: {
		onReady(editor) {
			editor.ui
				.getEditableElement()
				.parentElement.insertBefore(
					editor.ui.view.toolbar.element,
					editor.ui.getEditableElement()
				);
		},
	},
	beforeDestroy() {},
	components: {},
};
