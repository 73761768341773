import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';
import CDatePicker from '@/components/global/CDatePicker.vue';
import ContractForm from '@/components/agreement/elements/ContractForm/ContractForm.vue';
import SearchOwner from '@/components/contract/search/elements/SearchDocument/SearchDocument.vue';
import PreviewContract from '@/components/agreement/elements/PreviewContract/PreviewContract.vue';

export default {
	name: 'SearchAgreement',
	props: {},
	data: () => ({
		loading: false,
		moreFilters: false,
		filters: {
			page: 1,
			pagination: true,
			per_page: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
		},
		valid: false,
		requiredRule: [(v) => !!v || 'Campo requerido'],
		inputSearch: null,

		fields: [],
		timeout: null,
		isLoading: false,
		current_agreement: null,
	}),
	computed: {
		...mapState('agreement', [
			'documentTypes',
			'template',
			'agreements',
			'agreement',
			'loading_agreement',
			'agreementsPagination',
		]),
		breadcrumbs: function () {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Abastecimiento',
					link: true,
					exact: true,
					disable: false,
					to: {
						name: 'AgreementHome',
					},
				},
				{
					text: 'Búsqueda',
					disabled: true,
					href: '/',
				},
			];
		},
	},
	watch: {
		'filters.date_from'() {
			this.listenFilters();
		},
		'filters.date_to'() {
			this.listenFilters();
		},
	},
	created() {
		this.listDocumentType();
		this.cleanTemplate();
		this.cleanAgreement();
		this.cleanAgreements();
		this.searchQueryParams();
	},
	methods: {
		...mapActions('agreement', [
			'listDocumentType',
			'searchAgreement',
			'getTemplate',
			'getAgreement',
			'cleanTemplate',
			'cleanAgreement',
			'cleanAgreements',
			'cleanDocumentType',
		]),

		selectResult(result) {
			if (result?.id) {
				this.getAgreement(result?.id);
				this.current_agreement = result;
			}
		},
		isSelected(result) {
			return result?.id == this.current_agreement?.id ? 'selected' : '';
		},

		showAlert(color = '', msm = '') {
			$EventBus.$emit('showSnack', color || 'success', msm);
		},

		async getFormat() {
			const isvalidated = this.$refs.formContract.validate();
			if (isvalidated) {
				this.loading = true;
				const { id } = this.form.documentType;
				const { ok, error } = await this.getTemplate(id);
				if (!ok) {
					let msm = error?.data?.message;
					msm = Array.isArray(msm) ? msm.join(', ') : msm;
					this.showAlert('error', msm);
				}
				this.loading = false;
			}
		},

		async search() {
			if (this.filters) {
				this.isLoading = true;
				const { ok, error } = await this.searchAgreement(this.filters);
				if (!ok) {
					let msm = error?.data?.message;
					msm = Array.isArray(msm) ? msm.join('<br> ') : msm;
					this.showAlert('error', msm);
				}
			} else {
				this.cleanAgreements();
				this.current_agreement = null;
			}
			this.isLoading = false;
		},

		listenFilters() {
			this.filters.page = 1;
			this.search();
		},

		listenSearch(value) {
			clearTimeout(this.timeout);
			if ((value || '').length > 0 || value == '') {
				this.timeout = setTimeout(async () => {
					this.filters.q = value || null;
					await this.search();
					clearTimeout(this.timeout);
				}, 800);
			}
		},

		listenMoreFilters(v) {
			if (!v) {
				this.filters.date_from = null;
				this.filters.date_to = null;
				this.filters.document_type = null;
			}
		},

		searchQueryParams() {
			this.$nextTick(function () {
				if (this.$route?.query?.owner && this.$refs?.inputOwner) {
					this.$refs.inputOwner.setOwner(this.$route?.query?.owner);
				}
			});
		},
	},
	beforeDestroy() {
		this.cleanDocumentType();
		this.cleanTemplate();
		this.cleanAgreements();
		this.cleanAgreement();
	},
	components: {
		SearchOwner,
		ContractForm,
		CDatePicker,
		PreviewContract,
	},
};
